import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { showNotification } from 'react-admin'
import { withDataProvider, refreshView } from 'ra-core'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExportToOldAdminButton = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const handleClick = () => {
        setIsLoading(true)
        const { dispatch, history } = props;

        
        const id = props.record.id.split('/')[3]
        fetch(`${process.env.REACT_APP_ENTRYPOINT}/api/configurations/${id}/build`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.record)
        })
        .then((res) => {
            if (res.status !== 201) {
                setOpenError(true)
            } else {
                setOpenSuccess(true);
                refreshView()
            }
                setIsLoading(false)
                // history.push(`/issues/%2Fapi%2Fissues%2F${id}/show`)
        })
        

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
        setOpenError(false);
      };

    return (
        <>
            <Button disabled={isLoading} onClick={handleClick}>Create build</Button>
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Request to build is send
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Request to build is failed
                </Alert>
            </Snackbar>
      </>
    )
}


export default withDataProvider(ExportToOldAdminButton)
