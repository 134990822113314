import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    DeleteButton,
    ReferenceField,
    Filter,
    DateField,
    TextInput,
    SelectInput,
    ReferenceFieldController,
    ReferenceArrayField,
    SingleFieldList
} from 'react-admin';

import SimpleChipField from '../Common/BuildArray'

export const ListBuilds = props => (
    <List bulkActionButtons={false} title="Builds" filters={<BuildFilter/>} {...props} exporter={false}>
        <Datagrid>
            <FunctionField source="id" label="ID" render={r =>r.originId}/>
            <ReferenceField source="configuration['@id']" label="Configuration" reference="configurations">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="configuration['company']['@id']" label="Company" reference="companies">
                <TextField source="name" />
            </ReferenceField>
            <SimpleChipField {...props} label='Files' />
            <DateField source="dateCreate" showTime/>
            <DeleteButton/>
        </Datagrid>
    </List>
);


const roles = localStorage.getItem('roles')

const BuildFilter = (props) => (
    <Filter {...props} >
        <TextInput alwaysOn source="configuration.name" label="Configuration"/>
        <TextInput alwaysOn source="configuration.company.name" label="Company"/>
        <SelectInput alwaysOn source="status" label="Status" choices={[
            { id: 'new', name: 'new' },
            { id: 'error', name: 'error' },
            { id: 'ready', name: 'ready' },
            { id: 'in_progress', name: 'in_progress' },
        ]} />
    </Filter>
);