export async function transfromCompanies(data) {
  const feePools = []
  Object.keys(data).forEach(key => {
    if (key.includes('feePools(')) {
      feePools.push(...data[key])
      delete data[key]
    }
    data = removeNullFields(data)
  })
  if (data.mainPoolsByAlgo) {
    const mainPools = []
    Object.keys(data.mainPoolsByAlgo).forEach(key => {
      mainPools.push(data.mainPoolsByAlgo[key])
    })
    delete data.mainPoolsByAlgo
    data.mainPools = getIRIArray(mainPools)
  }
  delete data.mainPool
  data.feePools = feePools
  return data
}

function removeNullFields(obj) {
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      delete obj[key]
    } else if (typeof(obj[key]) === 'object') {
      obj[key] = removeNullFields(obj[key])
    }
  })
  return obj
}

function getIRIArray(arr) {
  return arr.map(v => typeof(v) === 'object' ? v['@id'] : v)
}