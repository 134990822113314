import React, { useState, useEffect } from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import CompanyIcon from '@material-ui/icons/Business';
import { MenuItem, Typography, Card, CardContent } from '@material-ui/core';
import { UserRoles } from './Component/Users/ListUser';

const AppMenu = props => {

    const [userRoles, setUserRoles] = useState([])
    const companyId = localStorage.getItem('company_id')
    const email = localStorage.getItem('email');
    const userId = localStorage.getItem('user_id');
    const companyUrl = `./admin#/companies/%2Fapi%2Fcompanies%2F${localStorage.getItem('company_id')}/show`
    const userUrl = `/users/%2Fapi%2Fusers%2F${localStorage.getItem('user_id')}/show`
    useEffect(() => {

        const rolesArray = JSON.parse(localStorage.getItem('roles')) || [];

        let roles = []
        rolesArray.map(e => roles.push(UserRoles[e] || e))

        setUserRoles(roles)
    }, [])
    const handleClick = () => {
        window.location.replace(userUrl)
    }

    return (
        <UserMenu {...props}>
            <Card onClick={() => { }} style={{ outline: 'none' }} variant="outlined">
                <CardContent>
                    <MenuItemLink 
                        to={userUrl}
                        primaryText={
                            `#${userId} ${email}` 
                        }
                    >
                    </MenuItemLink>
                    <Typography displayBlock alignCenter h6> {`${userRoles.join()}`}  </Typography>
                </CardContent>
                <CardContent>
                    <Typography variant='subtitle2'> {process.env.REACT_APP_VERSION} </Typography>
                </CardContent>
            </Card>
            {companyId !== null ?
                <MenuItem>

                    <CompanyIcon />
                    <a style={{
                        textDecoration: 'none',
                        color: 'gray',
                        marginLeft: '19px'
                    }}
                        href={companyUrl}
                    >
                        My company
                    </a>
                </MenuItem> : null
            }

        </UserMenu>
    )
};

export default AppMenu
