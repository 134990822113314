
 export const SET_API= 'SET_API'
 export const SET_REQUEST= 'SET_REQUEST'
 export const GET_REQUEST= 'GET_REQUEST'

export const setApi = (api) => ({
    type: SET_API,
    payload: api
})

export const setRequest = (request) => ({
    type: SET_REQUEST,
    payload: request
})
