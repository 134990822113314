import React, {Fragment} from 'react';
// import { Button } from 'react-admin';

const DownloadButton = (props) => {
    const {build} = props

    const createFileName = obj => {
      const { model, company, configuration, file } = obj
      const extension = file.split('.')[1]
      return `Antminer-${company}-${model}-${configuration}.${extension}`
    }

    const downloadData = (e) => {
        e.preventDefault()
        let url = `${process.env.REACT_APP_ENTRYPOINT}/uploads/${build.file}`
        let a = document.createElement('a')
        a.href = url
        a.download = createFileName(build)
        a.click()
        a.remove()
    }
    return (
        <Fragment>
            <button onClick={downloadData} label={'Download'} key="button" style={{marginLeft: '3px'}}>{ build.filename + '_' + build.model }</button>
        </Fragment>
    )
}

export default DownloadButton
