import { SET_API, SET_REQUEST } from "../app/actions"

const initialState = {
    request: null,
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case SET_REQUEST:
            return{
                ...state,
                request: payload
            }
         case SET_API:
             return{
                ...state,
                api: payload
            }
        default:
            return state
    }
}
