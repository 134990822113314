import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    BooleanField,
    ArrayField,
    NumberField,
    Datagrid,
    ReferenceField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField
} from 'react-admin';

import MakeBuild from '../Common/MakeBuild'

export const ShowConfiguration = (props) => (
    <Show {...props} title={'Configuration'}>
        <SimpleShowLayout>
            <TextField source="originId" label="ID" />
            <TextField source="company.name" label="Company" />
            <TextField source="name" label="Name" />
            <ReferenceArrayField label="Models" reference="models" source="models">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ArrayField source="pools" label="Pools">
                <Datagrid>
                    {/* <TextField source="name" label="Name"/> */}
                    <TextField source="url" label="Url"/>
                    <TextField source="worker" label="Worker"/>
                    <TextField source="password" label="Password"/>
                    <NumberField source="fee" label="Fee" />
                    <BooleanField label="Hidden" source="hidden" />
                </Datagrid>
            </ArrayField>
            <MakeBuild/>
            <BooleanField source="hideAllPools" label="All Pools Hidden" />
        </SimpleShowLayout>
    </Show>
);



