import React, { useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import DownloadFile from "./DownloadButton";
import { useVersion, useDataProvider } from "react-admin";
import { useState } from "react";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 8,
  },
  chip: { margin: 4 },
});

const SimpleChipField = (props) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const version = useVersion();
  const [model, setModel] = useState(null);
  const [company, setCompany] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [builds, setBuilds] = useState([]);

  const fetchFile = React.useCallback(async () => {
    if (record?.builds?.length) {
      let records = [];
      const buildsArr = await Promise.all(
        record.builds.map(async (build) => {
          const buildRecord = await dataProvider.getOne("models", {
            id: build,
          });
          const model = await dataProvider.getOne("models", {
            id: buildRecord.data.model["@id"],
          });
          records.push({
            file: buildRecord.data.file?.path,
            filename: buildRecord.data.file?.name.split("_")[0],
            model: model.data.name,
            company: record.configuration.company.name,
            configuration: record.configuration.name,
          });
        })
      );
      setBuilds(records);
    }
  });

  React.useEffect(() => {
    fetchFile();
  }, [record]);
  const classes = useStyles();
  return builds
    ? builds.map((build) => build.file ?(
        <span className={classes.main}>
          <DownloadFile build={build} />
        </span>
      ) : <></>)
    : null;
};

SimpleChipField.defaultProps = {
  addLabel: true,
};

export default SimpleChipField;
